<template>
  <div
    class="flex flex-column align-items-center content-error-page md:mt-s10 p-s3 not-found-container mx-auto"
  >
    <Icon name="error404" :size="isMobile ? '160' : '240'" />
    <div class="mt-s6 h1" :class="isMobile ? 'h3' : 'h1'">Page not found</div>
    <div class="mt-s4 text-center" :class="{ 'text-sm': isMobile }">
      If you don't much care where you want to get to, it doesn't matter which
      way you go.<br />
      But if you do, please, try to begin from the Blueshift main page —<br />this
      page actually doesn't exist at all...
    </div>
    <div class="mt-s6">
      <router-link to="/">
        <Button class="button-lg button-primary" label="Go to main page" />
      </router-link>
    </div>
  </div>
</template>

<script setup>
import Icon from '@/components/Icon.vue';
import { useLayout } from '@/composables/core/useLayout';

const { isMobile } = useLayout();
</script>

<style scoped lang="scss">
.not-found-container {
  height: calc(100vh - $header-height);
  max-width: 400px;
  justify-content: center;
}
</style>
